import React, { useState } from "react";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import BrandBanner from "./imgs/bannerweb_1_2000x500.png";
import { TokenIcon, WalletIcon, NetworkKucoin } from "@web3icons/react";
import TeamCard from "./components/TeamCard";
import TeamInfoCard from "./components/TeamInfoCard";
import Wojakpfp from "./imgs/Wojak_pfp.png";
import Crusaderpfp from "./imgs/Crusader_pfp.png";
import Hodlianpfp from "./imgs/Hodlian_pfp.png";
import Lisanpfp from "./imgs/Lisan_pfp.png";
import Novapfp from "./imgs/Nova_pfp.png";
import gatelogo from "./imgs/gate_logo.png";
import Footer from "./components/Footer";
import bybitlogo from "./imgs/bybit-seeklogo.png";
import maindev from "./imgs/maindev.png";
import { useExpand } from "./utils/hooks/useExpand";
import FAQ from "./components/FAQ";
import Devtools from "./pages/Devtools";
import NavMenu from "./components/NavMenu";
import Roadmap from "./components/roadmap";
{
  /**import { EmblaCarousel } from "./components/EmblaCarousel";*/
}

const App: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <Router>
      <div className="bg-brand-background-light text-gray-200 min-h-screen flex flex-col">
        <header className="flex sm:flex-row justify-between items-center shadow-lg relative">
        <NavMenu />
        </header>

        <main>
          <Routes>
            <Route path="/devtools" element={<Devtools />} />
            <Route
              path="/"
              element={<Home menuOpen={menuOpen} setMenuOpen={setMenuOpen} />}
            />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

interface HomeProps {
  menuOpen: boolean;
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Home: React.FC<HomeProps> = ({ menuOpen, setMenuOpen }) => {
  const { isExpanded, toggleExpand } = useExpand();
  const { isExpanded: isExpandedCommunity, toggleExpand: toggleExpandCommunity } = useExpand();
  const { isExpanded: isExpandedTeam, toggleExpand: toggleExpandTeam } = useExpand();

  const scrollToTelegram = () => {
    const telegramLink = document.getElementById("telegram-link");
    if (!menuOpen) {
      setMenuOpen(true);
    }

    setTimeout(() => {
      if (telegramLink) {
        telegramLink.scrollIntoView({ behavior: "smooth", block: "center" });

        telegramLink.classList.add("bg-brand-red", "text-white");
        setTimeout(() => {
          telegramLink.classList.remove("bg-brand-red", "text-white");
        }, 2000);
      }
    }, 200);
  };

  return (
    <>
      <section
        className="relative w-full"
        style={{
          backgroundImage: `url(${BrandBanner})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "contain",
        }}
      >
        <div style={{ paddingTop: "25%" }}></div>
      </section>
      {/** ABOUT SECTION */}
      <section>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 p-4 max-w-4xl mx-auto">
          <div className="flex flex-col justify-center items-center">
            <h2 className="text-center uppercase text-black font-montserrat text-3xl py-8">
              What is $DEV?
            </h2>
            <div className="relative bg-brand-blue-dark text-center p-4 rounded-md shadow-md text-white">
              <p>
                $DEV is a token created by an anonymous developer (The Honest
                Dev) who had enough of the Solana memecoin chaos, scammers,
                grifters, and celebrity cash grabs. Instead of playing their
                game, he airdropped 14% of the supply to Tier 1 exchanges,
                burned his remaining 1.33%, and set up social accounts.
              </p>
              <div className="flex flex-col">
                <span
                  className={`material-symbols-outlined cursor-pointer hover:text-[26px] h-fit p-2 absolute bg-brand-blue-light rounded-md shadow-md ${
                    isExpanded ? "left-[92%] top-[95%]" : "left-[92%] top-[86%]"
                  }`}
                  onClick={toggleExpand}
                >
                  {isExpanded ? "expand_circle_up" : "expand_circle_down"}
                </span>
              </div>
              {isExpanded && (
                <p className="mt-4">
                  Then, in true decentralized fashion, he passed everything over
                  to the community, making $DEV a truly community-driven
                  movement. 🔥 No cabals. No insiders. Just the blockchain and
                  the people.
                </p>
              )}
            </div>
          </div>
        </div>
      </section>
      {/** Why should i join the community SECTION */}
      <section>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 p-4 max-w-4xl mx-auto">
          <div className="flex flex-col justify-center items-center">
            <h2 className="text-center uppercase text-black font-montserrat text-3xl py-8">
              <a href="#tokenomics" className="inline-flex items-center gap-2">
                <span>Why Should I Join The Community?</span>
                <span className="animate-bounce">👈</span>
              </a>
            </h2>
            <div className="relative bg-brand-blue-dark text-center p-4 rounded-md shadow-md text-white">
              <p>
                We’re a real community built on trust, experience, and a shared
                vision. Many of us have been in the crypto space for years, and
                we’re here to help, educate, and guide anyone who wants to
                learn.
              </p>
              <p className="mt-4">
                Got questions about blockchain, DeFi, or the market? Just ask.
                Whether you're new or a seasoned trader, there’s always someone
                ready to share knowledge and support you. Some of our members
                even have master-level expertise in crypto and love breaking
                things down in a way that actually makes sense.
              </p>
              <div className="flex flex-col">
                <span
                  className={`material-symbols-outlined cursor-pointer hover:text-[26px] h-fit p-2 absolute bg-brand-blue-light rounded-md shadow-md ${
                    isExpandedCommunity ? "left-[92%] top-[96%]" : "left-[92%] top-[94%]"
                  }`}
                  onClick={toggleExpandCommunity}
                >
                  {isExpandedCommunity ? "expand_circle_up" : "expand_circle_down"}
                </span>
              </div>

              {isExpandedCommunity && (
                <>
                  <p className="mt-4">
                    But beyond knowledge, we’re here for something
                    bigger—bringing honesty back to crypto. In a space full of
                    scams and manipulation, we believe in transparency,
                    integrity, and building something real.
                  </p>
                  <p className="mt-4">
                    If that’s what you’re looking for, you’re in the right
                    place. Come hang out with us. 🚀🔥{" "}
                    <span className="block text-brand-red">
                      #NoGrift #TheHonestDev
                    </span>
                  </p>
                </>
              )}
            </div>
          </div>
          <div className="flex items-center justify-center">
            <img
              src={maindev}
              className="max-w-[40%] md:max-w-[70%] self-end"
              alt="Our Hero"
            />
          </div>
        </div>
      </section>
      <section
        className={`p-6 bg-brand-blue-dark text-white text-center mt-8 ${
          menuOpen ? "mt-28 md:mt-8" : "mt-8"
        }`}
      >
        <h2 className="text-2xl sm:text-3xl font-bold mb-4">
          14% Allocated to Major Exchanges
        </h2>
        <p className="text-lg sm:text-xl text-gray-100 max-w-4xl mx-auto leading-relaxed">
          <span className="font-bold text-brand-red">14%</span> of the total
          supply is distributed equally across major exchanges:
        </p>
        <div className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-4 gap-4 mt-4">
          <div className="flex flex-col items-center">
            <TokenIcon
              symbol="bnb"
              variant="branded"
              className="w-8 h-8 sm:w-10 sm:h-10"
            />
            <span className="text-gray-300 text-sm sm:text-base mt-2">
              Binance
            </span>
          </div>
          <div className="flex flex-col items-center">
            <WalletIcon
              id="coinbase"
              variant="branded"
              className="w-8 h-8 sm:w-10 sm:h-10"
            />
            <span className="text-gray-300 text-sm sm:text-base mt-2">
              Coinbase
            </span>
          </div>
          <div className="flex flex-col items-center">
            <WalletIcon
              id="kraken wallet"
              variant="branded"
              className="w-8 h-8 sm:w-10 sm:h-10"
            />
            <span className="text-gray-300 text-sm sm:text-base mt-2">
              Kraken
            </span>
          </div>
          <div className="flex flex-col items-center">
            <img src={bybitlogo} className="w-8 h-8 sm:w-10 sm:h-10" />
            <span className="text-gray-300 text-sm sm:text-base mt-2">
              Bybit
            </span>
          </div>
          <div className="flex flex-col items-center">
            <WalletIcon
              id="okx"
              variant="mono"
              className="w-8 h-8 sm:w-10 sm:h-10"
            />
            <span className="text-gray-300 text-sm sm:text-base mt-2">OKX</span>
          </div>
          <div className="flex flex-col items-center">
            <NetworkKucoin variant="mono" size={32} />
            <span className="text-gray-300 text-sm sm:text-base mt-2">
              Kucoin
            </span>
          </div>
          <div className="flex flex-col items-center">
            <img src={gatelogo} className="h-8 w-8" />
            <span className="text-gray-300 text-sm sm:text-base mt-2">
              Gate.io
            </span>
          </div>
        </div>
      </section>
      {/** MEET THE TEAM */}
      <div>
        <h2 className="text-center uppercase text-black font-montserrat text-3xl py-8 sm:pt-8 sm:pb-0">
          MEET THE TEAM
        </h2>
      </div>
      <section
        className="relative flex justify-center items-center 
                  px-8 pb-8 bg-[#EAD7C0] gap-8 shadow-md"
      >
        {/** Mobile default */}
        <div className="flex flex-col items-center gap-12 sm:hidden">
          <div className="flex flex-col gap-6 gap-x-4 bg-brand-blue-dark text-center p-4 rounded-md shadow-md text-white sm:hidden relative">
            <div className="flex justify-between w-full">
              <p>
                The CTO team consists of a dedicated group of community
                enthusiasts who all strive to uphold the core values of The
                Honest Dev. We purchased 3% of the token supply to ensure a
                stake in the project's success, and further securing funds for
                the community's growth.
              </p>
              <div className="flex flex-col">
                <span
                  className={`material-symbols-outlined cursor-pointer hover:text-[26px] h-fit p-2 absolute bg-brand-blue-light rounded-md shadow-md ${
                    isExpandedTeam ? "left-[92%] top-[95%]" : "left-[92%] top-[86%]"
                  }`}
                  onClick={toggleExpandTeam}
                >
                  {isExpandedTeam ? "expand_circle_up" : "expand_circle_down"}
                </span>
              </div>
            </div>

            {isExpandedTeam && (
              <p>
                Together, we work tirelessly to uphold the core values of The
                Honest Dev project, and drive its vision of honesty,
                transparency, and community empowerment. The team is also
                supported by additional members who prefer to stay anonymous,
                including marketing experts, developers, and social media
                enthusiasts who all contribute their expertise behind the
                scenes.
              </p>
            )}
          </div>
          {/** Add to stats: https://moralis.com/chain/solana/token/price/the-honest-dev-1 */}
          <div className="flex flex-col gap-16 sm:hidden relative">
            <div className="sm:hidden absolute top-0 bottom-0 left-1/2 transform -translate-x-1/2 w-[2px] bg-gray-400 z-0"></div>
            <TeamCard title="CTO TEAM" area="" role="" alt="CTO Avatar" />
            <TeamCard
              title="CRYPTO CRUSADER"
              area="Marketing manager"
              role="Roadmap idea bringer"
              img={Crusaderpfp}
              alt="Crypto Crusader Avatar"
            />
            <TeamCard
              title="HODLIAN"
              area="Account management"
              role="Community lead"
              img={Hodlianpfp}
              alt="Hodlian Avatar"
            />
            <TeamCard
              title="WOJAK"
              area="Tech lead"
              role="Code go brrrr"
              img={Wojakpfp}
              alt="Wojak Avatar"
            />
          </div>
          <div className="flex flex-col gap-16 sm:hidden">
            <TeamCard
              title="Lisan Al Gaib"
              area="Marketing"
              role="Social media manager"
              img={Lisanpfp}
              alt="Lisan Al Gaib Avatar"
            />
            <TeamCard
              title="Nova"
              area="Designer"
              role="UX and graphics"
              img={Novapfp}
              alt="Nova Avatar"
            />
          </div>
        </div>

        {/* SM and MD*/}
        <div className="hidden sm:block lg:hidden relative bg-[#EAD7C0] p-1">
          <div className="hidden sm:flex lg:hidden flex-col gap-16 mt-4">
            <div className="flex flex-col gap-6 gap-x-4 bg-brand-blue-dark text-center p-4 rounded-md shadow-md text-white lg:hidden relative">
              <div className="flex justify-between w-full">
                <p>
                  The CTO team consists of a dedicated group of community
                  enthusiasts who all strive to uphold the core values of The
                  Honest Dev They purchased 3% of the token supply to ensure a
                  stake in the project's success, and further securing funds for
                  the community's growth.{" "}
                </p>
                <div className="flex flex-col">
                  <span
                    className={`material-symbols-outlined cursor-pointer hover:text-[26px] h-fit p-2 absolute bg-brand-blue-light rounded-md shadow-md ${
                      isExpandedTeam
                        ? "left-[92%] top-[95%]"
                        : "left-[92%] top-[86%]"
                    }`}
                    onClick={toggleExpandTeam}
                  >
                    {isExpandedTeam ? "expand_circle_up" : "expand_circle_down"}
                  </span>
                </div>
              </div>

              {isExpandedTeam && (
                <p>
                  Together, we work tirelessly to uphold the core values of The
                  Honest Dev project, and drive its vision of honesty,
                  transparency, and community empowerment. The team is also
                  supported by additional members who prefer to stay anonymous,
                  including marketing experts, developers, and social media
                  enthusiasts who all contribute their expertise behind the
                  scenes.
                </p>
              )}
            </div>
            <div className="hidden sm:flex lg:hidden flex-row gap-4 justify-center relative">
              <TeamCard title="CTO TEAM" area="" role="" alt="CTO Avatar" />
              <div
                className="absolute w-[2px] bg-gray-400 z-0"
                style={{
                  top: "60px",
                  left: "50%",
                  height: "65px",
                }}
              ></div>
            </div>
            <div className="hidden sm:flex lg:hidden flex-row gap-16 relative">
              <div
                className="absolute h-[2px] bg-gray-400 z-0"
                style={{
                  top: "50%",
                  left: "50%",
                  transform: "translateX(-50%)",
                  width: "448px",
                }}
              ></div>

              <div
                className="absolute w-[2px] bg-gray-400 z-0"
                style={{
                  top: "50%",
                  left: "calc(50% - 224px)",
                  height: "48px",
                }}
              ></div>

              <div
                className="absolute w-[2px] bg-gray-400 z-0"
                style={{
                  top: "50%",
                  left: "50%",
                  height: "48px",
                }}
              ></div>

              <div
                className="absolute w-[2px] bg-gray-400 z-0"
                style={{
                  top: "50%",
                  left: "calc(50% + 224px)",
                  height: "48px",
                }}
              ></div>
            </div>
            {/** Team cards */}
            <div className="hidden sm:flex lg:hidden flex-row gap-16 relative justify-center">
              <TeamCard
                title="CRYPTO CRUSADER"
                area="Marketing manager"
                role="Roadmap idea bringer"
                img={Crusaderpfp}
                alt="Crypto Crusader Avatar"
              />
              <TeamCard
                title="HODLIAN"
                area="Account management"
                role="Community lead"
                img={Hodlianpfp}
                alt="Hodlian Avatar"
              />
              <TeamCard
                title="WOJAK"
                area="Tech lead"
                role="Code go brrrr"
                img={Wojakpfp}
                alt="Wojak Avatar"
              />
            </div>
            <div className="hidden sm:flex lg:hidden flex-row gap-16 justify-center">
              <TeamCard
                title="Lisan Al Gaib"
                area="Marketing"
                role="Social media manager"
                img={Lisanpfp}
                alt="Lisan Al Gaib Avatar"
              />
              <TeamCard
                title="Nova"
                area="Designer"
                role="UX and graphics"
                img={Novapfp}
                alt="Nova Avatar"
              />
            </div>
          </div>
        </div>

        {/* LG AND BEYOND*/}
        <div
          className="hidden lg:grid relative bg-[#EAD7C0] py-4 gap-y-10 gap-x-14 max-w-[1440px]"
          style={{
            gridTemplateColumns: "minmax(280px, 1fr) 1fr 1fr",
          }}
        >
          {/** ROW 1: Empty (col1), First Team Card, info box */}

          <div></div>
          <div className="self-center">
            <TeamCard
              title="CRYPTO CRUSADER"
              area="Marketing manager"
              role="Roadmap idea bringer"
              img={Crusaderpfp}
              alt="Crypto Crusader Avatar"
            />
          </div>
          <TeamInfoCard text="Crypto Crusader was inspired by The Honest Dev's vision of decentralization. With a master’s degree in blockchain technology, he is committed to developing clear plans and marketing strategies to ensure that The Honest Dev’s vision is seen and recognized around the world." />

          {/** ROW 2: CTO card, second team card, second info box */}
          <div className="relative flex flex-col self-center w-fit">
            <TeamCard title="CTO TEAM" area="" role="" alt="CTO Avatar" />
            <div
              className="absolute w-[2px] bg-gray-400 z-0"
              style={{
                top: "50%",
                left: "100%",
                height: "2px",
                width: "100%",
              }}
            ></div>
            <div
              className="absolute w-[2px] bg-gray-400 z-0"
              style={{
                top: "50%",
                left: "calc(100% + 60px)",
                height: "260px",
              }}
            ></div>
            <div
              className="absolute w-[2px] bg-gray-400 z-0"
              style={{
                bottom: "50%",
                left: "calc(100% + 60px)",
                height: "275px",
              }}
            ></div>
            <div
              className="absolute w-[2px] bg-gray-400 z-0"
              style={{
                bottom: "305px",
                left: "316px",
                height: "2px",
                width: "100%",
              }}
            ></div>
            <div
              className="absolute w-[2px] bg-gray-400 z-0"
              style={{
                top: "289px",
                left: "316px",
                height: "2px",
                width: "100%",
              }}
            ></div>
          </div>
          <div className="self-center">
            <TeamCard
              title="HODLIAN"
              area="Account management"
              role="Community lead"
              img={Hodlianpfp}
              alt="Hodlian Avatar"
            />
          </div>
          <TeamInfoCard text="Drawn to the project's potential to reshape the crypto meme/community landscape, Hodlian believes in the transformative power of blockchain. With a master's degree in blockchain and digital currencies, Hodlian excels in building strong community conntections." />

          {/** ---------- ROW 3 ---------- */}
          <div />
          <div className="self-center">
            <TeamCard
              title="WOJAK"
              area="Tech lead"
              role="Code go brrrr"
              img={Wojakpfp}
              alt="Wojak Avatar"
            />
          </div>
          <TeamInfoCard text="Enthralled by The Honest Dev mission, Wojak brings technical expertise and a passion for innovation to the project. As a caffeine fueled tech-nerd armed with an infinite supply of White Monster energy drinks, Wojak codes whatever needs coding!" />
          {/** ---------- ROW 4 ---------- */}
          <div />
          <div className="self-center">
            <TeamCard
              title="Lisan Al Gaib"
              area="Marketing"
              role="Social media manager"
              img={Lisanpfp}
              alt="Lisan Al Gaib Avatar"
            />
          </div>
          <TeamInfoCard text="Inspired by the growing The Honest Dev community, Lisan Al Gaib embraced the role of engaging the community through creative content. A true defen with a knack for crafting entertaining TikTok videos, Lisan plays an important role in connecting with the community" />

          {/** ---------- ROW 5 ---------- */}
          <div />
          <div className="self-center">
            <TeamCard
              title="Nova"
              area="Designer"
              role="UX and graphics"
              img={Novapfp}
              alt="Nova Avatar"
            />
          </div>
          <TeamInfoCard text="Nova is the creative heart of the team. She brings her skills in UX design and graphics to the design, helping poor design-blind Wojak make stuff look a lot more prettier!" />
        </div>
      </section>
      <div className="w-auto bg-brand-blue-dark h-6"></div>
      <FAQ onScrollToTelegram={scrollToTelegram} />
      <div className="w-auto bg-brand-blue-dark h-6"></div>
      {/**<EmblaCarousel />*/}
      <section
        className="relative flex justify-center items-center 
                  px-8 pb-8 bg-[#EAD7C0] gap-8 shadow-md"
      >
          <Roadmap />
      </section>
      <div className="w-auto bg-brand-blue-dark h-6"></div>
      <section>
        <div className="max-w-4xl mx-auto self-center p-4 my-4 rounded-md">
          <div className="bg-brand-background-dark rounded-lg p-6 pl-8 shadow-md">
            <p className="text-black">
              This token is purely community-driven with no expectation of
              financial return. It exists to promote The Honest Dev's
              vision—bringing transparency, integrity, and trust back to the
              crypto space. A movement, not just a token.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default App;
