import React from 'react';
import { Link } from 'react-router-dom';

const Devtools: React.FC = () => {
  return (
    <div className="relative flex items-center justify-center min-h-screen bg-brand-background-light">
      <div className="absolute inset-0 bg-black opacity-50 blur"></div>
      <div className="relative z-10 text-center text-white">
        <h1 className="text-4xl font-bold mb-4">Coming Soon</h1>
        <p className="mb-8">Our Devtools section is under construction. Stay tuned!</p>
        <Link to="/" className="text-brand-red underline hover:text-red-400 transition duration-300">
          Back to main page
        </Link>
      </div>
    </div>
  );
};

export default Devtools;