import React from "react"
import ChartComponent from "./chartComponent"
import { Check, Flame, Percent, Droplets, Building } from "lucide-react"

export default function Tokenomics() {
    return (
        <div className="max-w-4xl mx-auto p-8 rounded-2xl bg-gradient-to-br from-amber-50 to-amber-100 shadow-lg">
        <div className="grid md:grid-cols-2 gap-4">
          <div className="space-y-6">
            <div id="tokenomics">
              <h3 className="text-lg sm:text-xl mb-3 text-amber-900">Tokenomics</h3>
              <p className="text-black text-lg mb-6">
                The Honest Dev (<span className="text-red-500 font-semibold">$DEV</span>) is built for transparency,
                fairness, and decentralization.
              </p>
            </div>
  
            <div className="space-y-4">
              <div className="flex items-start gap-3">
                <div className="mt-1 bg-amber-200 p-2 rounded-lg">
                  <Building className="h-5 w-5 text-amber-800" />
                </div>
                <div>
                  <h3 className="font-bold text-amber-900">Total supply</h3>
                  <p className="text-black">
                    1 Billion (<span className="text-red-500 font-semibold">$DEV</span>)
                  </p>
                </div>
              </div>
  
              <div className="flex items-start gap-3">
                <div className="mt-1 bg-amber-200 p-2 rounded-lg">
                  <Flame className="h-5 w-5 text-amber-800" />
                </div>
                <div>
                  <h3 className="font-bold text-amber-900">Burn Mechanism</h3>
                  <p className="text-black">1.33% burned directly from The Honest Dev 🔥</p>
                </div>
              </div>
  
              <div className="flex items-start gap-3">
                <div className="mt-1 bg-amber-200 p-2 rounded-lg">
                  <Percent className="h-5 w-5 text-amber-800" />
                </div>
                <div>
                  <h3 className="font-bold text-amber-900">Buy/Sell TAX</h3>
                  <p className="text-black">0% – No hidden fees, no BS 💯</p>
                </div>
              </div>
  
              <div className="flex items-start gap-3">
                <div className="mt-1 bg-amber-200 p-2 rounded-lg">
                  <Droplets className="h-5 w-5 text-amber-800" />
                </div>
                <div>
                  <h3 className="font-bold text-amber-900">Liquidity Pool</h3>
                  <p className="text-black">Burned via Pump fun 💎</p>
                </div>
              </div>
  
              <div className="flex items-start gap-3">
                <div className="mt-1 bg-amber-200 p-2 rounded-lg">
                  <Check className="h-5 w-5 text-amber-800" />
                </div>
                <div>
                  <h3 className="font-bold text-amber-900">Exchange Distribution (14%)</h3>
                  <p className="text-black">Fairly split across top exchanges (2% each):</p>
                  <p className="text-black mt-1">Binance | Coinbase | Kraken | Bybit | OKX | Gate.io | KuCoin</p>
                </div>
              </div>
            </div>
          </div>
            <ChartComponent />
        </div>
      </div>
      )
    }