import React from 'react';


interface TeamCardProps {
  title: string;  // e.g. "CTO TEAM"
  area: string;   // e.g. "Technology"
  role: string;   // e.g. "Strategic oversight"
  img?: string;    // path or URL to the avatar image
  alt?: string;   // optional alt text for the image
}

const TeamCard: React.FC<TeamCardProps> = ({ title, area, role, img, alt }) => {
  return (
    <div className="relative bg-brand-blue-dark text-center p-4 rounded-md shadow-md w-64 sm:w-40 lg:w-64 lg:z-10">
      {/* Overlapping Avatar Circle */}
      {img && <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 w-16 h-16 rounded-full overflow-hidden ring-4 ring-[#FFD700]">
        <img src={img} alt={alt || ''} className="object-cover bg-black w-full h-full" />
      </div>
      }

      {/* Card Content */}
      <div className={`${img ? 'mt-8' : ''}`}>
        <p className="font-raleway text-xl font-bold uppercase text-brand-background-light">{title}</p>
        <p className="font-raleway font-normal uppercase">{area}</p>
        <p className="font-raleway font-normal uppercase">{role}</p>
      </div>
    </div>
  );
};

export default TeamCard;
