import React from "react";

const Footer = () => {
  return (
    <footer className="p-4 bg-brand-blue-dark text-center shadow-lg text-gray-300">
      <p>
        © 2025 Honest Dev (DEV). All rights reserved. Built with care for the
        crypto community.
      </p>
      <div className="flex justify-center items-center space-x-2 mt-2 text-sm">
        <span>Website by</span>
        <a
          href="https://x.com/nicewojak"
          target="_blank"
          rel="noopener noreferrer"
          className="text-brand-red hover:text-red-400 transition duration-300"
        >
          @nicewojak
        </a>
        <span>💻</span>
      </div>
    </footer>
  );
};

export default Footer;
