import React, { useState, useMemo } from "react";
import { Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';

// Register Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

const ChartComponent: React.FC = () => {
  // Data
  const [originalData] = useState<number[]>([
    2, // Binance
    2, // Coinbase
    2, // Kraken
    2, // Bybit
    2, // OKX
    2, // Gate
    2, // Kucoin
    1.33, // Token burn
    17.9, // Liq. Pool
    3, // CTO Team
    63.74, // Community
  ]);

  const [activeSections, setActiveSections] = useState<boolean[]>(
    () => Array(originalData.length).fill(true)
  );

  const toggleSlice = (index: number) => {
    setActiveSections((prev) => {
      const newActive = [...prev];
      newActive[index] = !newActive[index];
      return newActive;
    });
  };

  const chartDataValues = useMemo(() => {
    return originalData.map((val, idx) =>
      activeSections[idx] ? val : 0
    );
  }, [originalData, activeSections]);

  const data = useMemo(() => ({
    labels: [
      'Binance (2%)',
      'Coinbase (2%)',
      'Kraken (2%)',
      'Bybit (2%)',
      'OKX (2%)',
      'Gate (2%)',
      'Kucoin (2%)',
      'Token burn',
      'Liq. Pool',
      'CTO Team (3%)',
      'Community',
    ],
    datasets: [
      {
        label: 'Distribution',
        data: [...chartDataValues],
        backgroundColor: [
          'rgba(181, 18, 27, 1)', // #b5121b
          'rgba(31, 92, 90, 1)', // #1f5c5a
          'rgba(15, 26, 42, 1)', // #0f1a2a
          'rgba(14, 116, 144, 1)', // #0e7490
          'rgba(112, 112, 112, 1)', // #707070
          'rgb(196, 163, 90)', // #c4a35a
          'rgb(181, 127, 80)', //rgb(181, 127, 80)
          'rgba(15, 26, 42, 0.6)', // #0f1a2a
          'rgb(234, 215, 192)', //rgb(234, 215, 192)
          'rgb(248, 245, 211)', //rgb(241, 241, 241)
          'rgb(94, 51, 13)', //rgb(94, 51, 13)
        ],
        borderColor: [
          'rgb(127, 22, 27)', // #b5121b
          'rgba(31, 92, 90, 1)', // #1f5c5a
          'rgba(15, 26, 42, 1)', // #0f1a2a
          'rgba(14, 116, 144, 1)', // #2bf3f58
          'rgba(112, 112, 112, 1)', // #707070
          'rgb(196, 163, 90)', // #c4a35a
          'rgb(181, 127, 80)', // rgb(181, 127, 80)
          'rgba(112, 112, 112, 1)', // #0f1a2a
          'rgb(234, 215, 192)', //rgb(234, 215, 192)
          'rgb(241, 241, 241)', // #707070
          'rgb(94, 51, 13)', //rgb(94, 51, 13)
        ],
        borderWidth: 1,
      },
    ],
  }), [chartDataValues]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed !== null) {
              label += context.parsed + '%';
            }
            return label;
          },
        },
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        titleColor: 'white',
        bodyColor: 'white',
        borderColor: 'rgba(255, 255, 255, 0.3)',
        borderWidth: 1,
      },
    },
  };

  return (
    <div className="flex flex-col justify-center items-center w-full">
      <div className="relative h-64 w-full max-w-[85%] sm:max-w-unset">
        <Doughnut data={data} options={options} />
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="text-center">
            <p className="text-sm font-medium text-amber-800">Total</p>
            <p className="text-2xl font-bold text-amber-900">1B</p>
            <p className="text-sm font-medium text-amber-800">$DEV</p>
          </div>
        </div>
      </div>

      <div className="mt-6 grid grid-cols-2 gap-2 w-fit md:w-auto">
        {data.labels.map((label, index) => {
          const isActive = activeSections[index];
          return (
            <button
              key={index}
              onClick={() => toggleSlice(index)}
              className="flex items-center gap-2 text-left focus:outline-none"
            >
              <div
                className="w-3 h-3 rounded-full"
                style={{
                  backgroundColor: data.datasets[0].backgroundColor[index] as string,
                  opacity: isActive ? 1 : 0.2, // Dim if off
                }}
              />
              <span
                className={`text-xs text-amber-800 ${
                  isActive ? "" : "line-through opacity-70"
                }`}
              >
                {label}
              </span>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default ChartComponent;