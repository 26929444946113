import React, { useState } from "react";
import { CheckCircle, Circle, ArrowRight } from "lucide-react";
import { motion } from "framer-motion";

interface RoadmapPhase {
    number: number
    title: string
    points: string[]
    isCurrent: boolean
    isCompleted: boolean
  }

  export default function Roadmap() {
    const [expandedPhase, setExpandedPhase] = useState<number | null>(3)

    const phases: RoadmapPhase[] = [
        {
          number: 1,
          title: "The Fair Launch",
          points: [
            "Launched fairly by The Honest Dev.",
            "14% of supply airdropped to 7 top exchanges.",
            "1.33% supply burned for transparency.",
          ],
          isCurrent: false,
          isCompleted: true,
        },
        {
          number: 2,
          title: "The Community Awakens",
          points: [
            "Telegram & Twitter created to build an initial community.",
            "Members fueled organic growth with memes, art, and music.",
            "First official community website launched.",
            "The Honest Dev then left, making the project fully decentralized.",
          ],
          isCurrent: false,
          isCompleted: true,
        },
        {
          number: 3,
          title: "Full Community Ownership (Current phase)",
          points: [
            "Social media accounts fully handed to the community.",
            "Interactive games, weekly competitions, and an updated website drive engagement.",
            "Building a strong community and educating new supporters for sustainable growth.",
          ],
          isCurrent: true,
          isCompleted: false,
        },
        {
          number: 4,
          title: "Expansion & Recognition",
          points: [
            "Presence at crypto expos for greater exposure.",
            "Development of Web3 tools to give real utility to $DEV, supporting the Solana ecosystem.",
            "NFT collection launched, integrating $DEV deeper into Web3.",
          ],
          isCurrent: false,
          isCompleted: false,
        },
        {
          number: 5,
          title: "The Endgame",
          points: [
            "A powerful, yet-to-be-unveiled vision shaped entirely by the community.",
            "$DEV aims to be a sustainable meme token, not just another pump-and-dump.",
          ],
          isCurrent: false,
          isCompleted: false,
        },
      ]

      const togglePhase = (phaseNumber: number) => {
        if (expandedPhase === phaseNumber) {
          setExpandedPhase(null)
        } else {
          setExpandedPhase(phaseNumber)
        }
      }
      return (
        <div className="w-full mx-auto py-12 px-4 md:px-6">
          <h2 className="text-3xl md:text-4xl uppercase text-black font-montserrat text-center mb-12">Project Roadmap</h2>
    
          <div className="relative">
            {/* Vertical line for desktop */}
            <div className="hidden md:block absolute left-[50%] top-0 bottom-0 w-0.5 bg-gradient-to-b from-brand-red/80 to-brand-red/20 transform -translate-x-1/2 z-0" />
    
            {phases.map((phase, index) => (
              <div key={phase.number} className="mb-8 md:mb-0">
                <div
                  className={`flex flex-col md:flex-row md:items-center md:justify-between 
                    ${index % 2 === 0 ? "md:flex-row-reverse" : ""}`
                  }
                >
                  {/* Phase indicator */}
                  <div className="flex items-center mb-4 md:mb-0">
                    <div
                      className={
                        `w-10 h-10 rounded-full flex items-center justify-center z-10 
                        ${phase.isCompleted && "bg-green-500"} 
                        ${phase.isCurrent ? "bg-brand-blue-light" : "bg-brand-blue-light"}`
                      }
                    >
                      {phase.isCompleted ? (
                        <CheckCircle className="w-6 h-6 text-white" />
                      ) : phase.isCurrent ? (
                        <Circle className="w-6 h-6 text-white" />
                      ) : (
                        <Circle className="w-6 h-6 text-gray-500 dark:text-gray-400" />
                      )}
                    </div>
                    <div className="ml-4 md:ml-0 md:absolute md:left-1/2 md:transform md:-translate-x-1/2 md:w-auto">
                      <div
                        className={`px-3 py-1 rounded-full text-sm font-medium
                            ${phase.isCompleted && "bg-green-100 text-green-800" }
                            ${phase.isCurrent ? "bg-brand-background-dark/90 text-white" : "bg-brand-blue-light text-gray-200"}`
                        }
                      >
                        Phase {phase.number}
                      </div>
                    </div>
                  </div>
    
                  {/* Phase content */}
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 }}
                    className={`w-full md:w-[45%] p-6 rounded-lg shadow-md cursor-pointer ${phase.isCurrent ? "bg-gradient-to-br from-amber-50 to-amber-100 bg-brand-background-dark border border-brand-background-light hover:border-brand-background-dark transition-colors" : "bg-brand-background-dark border border-brand-background-light hover:border-brand-background-dark transition-colors"}`}
                    onClick={() => togglePhase(phase.number)}
                  >
                    <div className="flex justify-between items-center mb-2">
                      <h3 className={`text-xl text-black font-raleway font-bold`}>{phase.title}</h3>
                      <ArrowRight
                        className={`w-5 h-5 transition-transform ${phase.isCurrent && "text-black"} ${expandedPhase === phase.number && "rotate-90"}`}
                      />
                    </div>
    
                    {expandedPhase === phase.number && (
                      <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ opacity: 0, height: 0 }}
                        className="mt-4"
                      >
                        <ul className="space-y-2">
                          {phase.points.map((point, i) => (
                            <li key={i} className="flex items-start">
                              <span className="inline-block w-2 h-2 rounded-full bg-black mt-1.5 mr-2 flex-shrink-0"></span>
                              <span className="text-black">{point}</span>
                            </li>
                          ))}
                        </ul>
                      </motion.div>
                    )}
                  </motion.div>
                </div>
    
                {/* Connector for mobile */}
                {index < phases.length - 1 && (
                  <div className="ml-5 h-8 w-0.5 bg-gradient-to-b from-primary/80 to-primary/20 md:hidden" />
                )}
              </div>
            ))}
          </div>
        </div>
      )
  }