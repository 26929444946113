import React from "react";
import { ChevronDown, Copy, ExternalLink } from "lucide-react";
import Tokenomics from "./Tokenomics";

interface FAQProps {
  onScrollToTelegram: () => void;
}

const FAQ: React.FC<FAQProps> = ({ onScrollToTelegram }) => {
  return (
    <section id="tokenomics" className="p-4 md:p-8 bg-brand-background-light text-black">
      <div className="space-y-6 max-w-4xl mx-auto">
        <div className="bg-brand-background-dark rounded-lg p-4 pl-10 shadow-md relative">
        <div
            className="absolute w-6 h-6 sm:w-12 sm:h-12 -left-3 sm:-left-4 top-1/2 transform -translate-y-1/2 bg-brand-blue-dark border-[4px] border-brand-blue-light rounded-lg"
          ></div>
          <h3 className="text-lg sm:text-xl font-semibold">Contract address</h3>
          <p className="text-black mt-2 break-words">
            FJsU8cSyKUgHwJgoB2LpuB9G3m7V47wfNwESndyWpump
          </p>
          <a
            href="https://solscan.io/token/FJsU8cSyKUgHwJgoB2LpuB9G3m7V47wfNwESndyWpump"
            target="_blank"
            rel="noopener noreferrer"
            className="mt-1 inline-flex items-center text-sm text-black hover:text-blue-600 transition duration-300 hover:underline"
          >
            Solscan
            <ExternalLink className="ml-1 h-3.5 w-3.5" />
          </a>
        </div>

        {/** Make text more clear, larger, bigger */}
        <div className="relative rounded-lg flex items-center justify-center">
          <Tokenomics />
        </div>
        <div className="bg-brand-background-dark rounded-lg p-4 pl-10 shadow-md relative">
          <div
            className="absolute w-6 h-6 sm:w-12 sm:h-12 -left-3 sm:-left-4 top-1/2 transform -translate-y-1/2 bg-brand-blue-dark border-[4px] border-brand-blue-light rounded-lg"
          ></div>
          <h3 className="text-lg sm:text-xl font-semibold">
            How can I join the community?
          </h3>
          <p className="text-black mt-2">
            Currently, we have 700+ active members on our Telegram, and
            growing!{" "}
            <button
              onClick={onScrollToTelegram}
              className="text-black underline hover:text-blue-600 transition duration-300"
            >
              Check out links at the top of our page
            </button>
          </p>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
