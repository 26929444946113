import React from "react";

interface TeamInfoCardProps {
  text: string; // e.g. "CTO TEAM"
}

const TeamInfoCard: React.FC<TeamInfoCardProps> = ({ text }) => {
  return (
    <div className="relative bg-brand-blue-dark text-center p-4 rounded-md shadow-md text-white z-10">
      <div
        className="
    absolute w-6 h-12 opacity-75
    -left-6 top-1/2 transform -translate-y-1/2
    bg-brand-blue-light  
    rounded-l-md
    z-[5]
  "
      ></div>
      <p className="text-md p-2">{text}</p>
    </div>
  );
};

export default TeamInfoCard;
