import React, { useState } from "react"
import { Menu, X, Users, BarChart2, Wrench, ChevronDown } from "lucide-react"
import BrandIcon from "../imgs/thedev.png";
import { Link } from "react-router-dom";

export default function NavMenu() {
    const [isOpen, setIsOpen] = useState(false)
    const [activeDropdown, setActiveDropdown] = useState<string | null>(null)
  
    const toggleDropdown = (dropdown: string) => {
      if (activeDropdown === dropdown) {
        setActiveDropdown(null)
      } else {
        setActiveDropdown(dropdown)
      }
    }
  
    const menuItems = [
      {
        id: "socials",
        title: "SOCIALS",
        icon: <Users className="h-5 w-5" />,
        items: [
          { name: "Telegram", href: "https://t.me/The_Honest_Dev", id: "telegram-link"},
          { name: "X", href: "https://x.com/TheHonest_Dev", id: "x-link" },
        ],
      },
      {
        id: "stats",
        title: "STATS",
        icon: <BarChart2 className="h-5 w-5" />,
        items: [
          { name: "Tokenomics", href: "#tokenomics", id: "tokenomics-link" },
          { name: "Dexscreener", href: "https://dexscreener.com/solana/5y8EfhBiwK2Yrzdoer7Au21F1ZpU7YoDyBNrrPmPk1FE", id: "dexscreener-link" },
          { name: "Dextools", href: "https://www.dextools.io/token/thehonestdev?t=1739825400122", id: "dextools-link" },
          { name: "Solana Explorer", href: "https://solscan.io/token/FJsU8cSyKUgHwJgoB2LpuB9G3m7V47wfNwESndyWpump", id: "solana-explorer-link" },
          { name: "Moralis", href: "https://moralis.com/chain/solana/token/price/the-honest-dev-1", id: "moralis-link" },
        ],
      },
      {
        id: "tools",
        title: "TOOLS",
        icon: <Wrench className="h-5 w-5" />,
        items: [
          { name: "Devtools", href: "/devtools", id: "devtools-link" },
          { name: "Flappy Dev", href: "https://play.google.com/store/apps/details?id=com.torpila.flappydev", id: "flappy-dev-link" },
        ],
      },
    ]
  
    return (
      <nav className="relative w-full">
        <div className="hidden md:block">
          <div className="bg-brand-background-light py-3 px-6">
            <div className="flex items-center justify-between">
              <Link to="/" className="flex items-center gap-2">
                <div className="relative h-10 w-10">
                  <img
                    src={BrandIcon}
                    alt="The Honest Dev Logo"                   
                    className="object-contain rounded-full border border-brand-red"
                  />
                </div>
                <span className="text-xl font-bold text-brand-red">The Honest Dev (DEV)</span>
              </Link>
            </div>
          </div>
          <div className="bg-brand-blue-light text-white">
            <div className="flex justify-center">
              {menuItems.map((category) => (
                <div key={category.id} className="relative group">
                  <button
                    onClick={() => toggleDropdown(category.id)}
                    className="flex items-center gap-2 px-8 py-3 hover:bg-slate-600 transition-colors"
                  >
                    {category.icon}
                    <span className="font-medium">{category.title}</span>
                    <ChevronDown className="h-4 w-4" />
                  </button>
                  <div
                    className={`absolute left-0 z-10 w-48 bg-brand-blue-dark shadow-lg ${activeDropdown === category.id ? "block" : "hidden"} group-hover:block`}
                  >
                    {category.items.map((item) => {
                      if (item.href.includes("#")) {
                        const el = document.getElementById(item.href.slice(1));
                        return (
                          <a
                            key={item.name}
                            href={item.href}
                            className="block px-4 py-3 hover:bg-brand-blue-light transition-colors"
                          >
                            {item.name}
                          </a>
                        );
                      } else {
                        return (
                          <Link
                            key={item.name}
                            to={item.href}
                            className="block px-4 py-3 hover:bg-brand-blue-light transition-colors"
                          >
                            {item.name}
                          </Link>
                        );
                      }
                    })}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
  
        <div className="md:hidden bg-brand-background-light">
          <div className="flex items-center justify-between p-4">
            <Link to="/" className="flex items-center gap-2">
              <div className="relative h-8 w-8">
                <img
                  src={BrandIcon}
                  alt="The Honest Dev Logo"                  
                  className="object-contain rounded-full border border-brand-red"
                />
              </div>
              <span className="text-lg font-bold text-brand-red">$DEV</span>
            </Link>
            <button onClick={() => setIsOpen(!isOpen)} className="p-2 rounded-md bg-brand-blue-light text-white">
              {isOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </button>
          </div>
        </div>
  
        {isOpen && (
          <div className="md:hidden absolute top-full left-0 right-0 z-50 bg-brand-blue-light text-white shadow-lg">
            {menuItems.map((category) => (
              <div key={category.id}>
                <button
                  onClick={() => toggleDropdown(category.id)}
                  className="flex items-center justify-between w-full px-4 py-3 border-b border-slate-600"
                >
                  <div className="flex items-center gap-2">
                    {category.icon}
                    <span className="font-medium">{category.title}</span>
                  </div>
                  <ChevronDown
                    className={`h-4 w-4 transition-transform ${activeDropdown === category.id ? "rotate-180" : ""}`}
                  />
                </button>
                {activeDropdown === category.id && (
                  <div className="bg-brand-blue-dark">
                    {category.items.map((item) => {
                      if (item.href.includes("#")) {
                        const el = document.getElementById(item.href.slice(1));
                        return (
                          <a
                            key={item.name}
                            href={item.href}
                            onClick={(e) => {
                              e.preventDefault(); 
                              el?.scrollIntoView({ behavior: "smooth" })}}
                            className="block px-4 py-3 hover:bg-brand-blue-light transition-colors"
                          >
                            {item.name}
                          </a>
                        );
                      } else {
                        return (
                          <Link
                            key={item.name}
                            to={item.href}
                            className="block px-4 py-3 hover:bg-brand-blue-light transition-colors"
                          >
                            {item.name}
                          </Link>
                        );
                      }
                    })}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </nav>
    )
  }