import { useState } from "react";

export function useExpand(initial = false) {
  const [isExpanded, setIsExpanded] = useState(initial);

  function toggleExpand() {
    setIsExpanded((prev) => !prev);
  }

  return { isExpanded, toggleExpand };
}